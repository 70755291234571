import React from 'react';
import { Category } from '../../../interfaces/interfaces';

export default function Stats({ categories }: { categories: Category[] }) {
  return (
    <div>
      <h3 className="text-distinct mb-5">Stats</h3>
      <div className="w-full">
        {categories.map((category, i) => (
          <div
            key={i}
            className="p-2"
            style={{
              backgroundColor: category.color,
              width: (category.questions / 200) * 100 + '%',
            }}
          >
            {category.name} ({category.questions})
          </div>
        ))}
      </div>
    </div>
  );
}
